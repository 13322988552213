@font-face {
  font-family: 'TondoCyr';
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/TondoCyrRegular.eot');
  src:
    url('/fonts/TondoCyrRegular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/TondoCyrRegular.woff') format('woff'),
    url('/fonts/TondoCyrRegular.ttf') format('truetype'),
    url('/fonts/TondoCyrRegular.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'TondoCyr';
  font-weight: bold;
  font-style: normal;
  src: url('/fonts/TondoCyrBold.eot');
  src:
    url('/fonts/TondoCyrBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/TondoCyrBold.woff') format('woff'),
    url('/fonts/TondoCyrBold.ttf') format('truetype'),
    url('/fonts/TondoCyrBold.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'TondoCyr';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/TondoCyrLight.eot');
  src:
    url('/fonts/TondoCyrLight.eot?#iefix') format('embedded-opentype'),
    url('/fonts/TondoCyrLight.woff') format('woff'),
    url('/fonts/TondoCyrLight.ttf') format('truetype'),
    url('/fonts/TondoCyrLight.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'FiraSans';
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/FiraSansRegular.eot');
  src:
    url('/fonts/FiraSansRegular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/FiraSansRegular.woff') format('woff'),
    url('/fonts/FiraSansRegular.ttf') format('truetype'),
    url('/fonts/FiraSansRegular.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'FiraSans';
  font-weight: normal;
  font-style: italic;
  src: url('/fonts/FiraSansRegularItalic.eot');
  src:
    url('/fonts/FiraSansRegularItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/FiraSansRegularItalic.woff') format('woff'),
    url('/fonts/FiraSansRegularItalic.ttf') format('truetype'),
    url('/fonts/FiraSansRegularItalic.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'FiraSans';
  font-weight: bold;
  font-style: normal;
  src: url('/fonts/FiraSansBold.eot');
  src:
    url('/fonts/FiraSansBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/FiraSansBold.woff') format('woff'),
    url('/fonts/FiraSansBold.ttf') format('truetype'),
    url('/fonts/FiraSansBold.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'FiraSans';
  font-weight: bold;
  font-style: italic;
  src: url('/fonts/FiraSansBoldItalic.eot');
  src:
    url('/fonts/FiraSansBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/FiraSansBoldItalic.woff') format('woff'),
    url('/fonts/FiraSansBoldItalic.ttf') format('truetype'),
    url('/fonts/FiraSansBoldItalic.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'FiraSans';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/FiraSansLight.eot');
  src:
    url('/fonts/FiraSansLight.eot?#iefix') format('embedded-opentype'),
    url('/fonts/FiraSansLight.woff') format('woff'),
    url('/fonts/FiraSansLight.ttf') format('truetype'),
    url('/fonts/FiraSansLight.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'FiraSans';
  font-weight: 600;
  font-style: normal;
  src: url('/fonts/FiraSansMedium.eot');
  src:
    url('/fonts/FiraSansMedium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/FiraSansMedium.woff') format('woff'),
    url('/fonts/FiraSansMedium.ttf') format('truetype'),
    url('/fonts/FiraSansMedium.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'FiraSans';
  font-weight: 600;
  font-style: italic;
  src: url('/fonts/FiraSansMediumItalic.eot');
  src:
    url('/fonts/FiraSansMediumItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/FiraSansMediumItalic.woff') format('woff'),
    url('/fonts/FiraSansMediumItalic.ttf') format('truetype'),
    url('/fonts/FiraSansMediumItalic.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'Pattaya';
  font-weight: normal;
  font-style: normal;
  src:
    url('/fonts/PattayaRegular.ttf') format('truetype');
}