// ---- LINKS ---- //

a {
	text-decoration: none;
	color: @color-orange;
	transition: @transition-duration;
	cursor: pointer;
	&:hover {
		color: @color-gray;
	}
	&.m--border-hover:hover {
		border-bottom: 1px solid @color-orange;
		transition: @transition-duration;
	}
}

.content a {
	&:hover {
		border-bottom: 1px solid @color-orange;
	}
}