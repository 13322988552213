// ---- ABOUT ---- //

.about {
	padding-bottom: 60px;
	&__block {
		padding-bottom: 20px;
		margin-bottom: 75px;
		border-bottom: 1px solid @color-white;
	    display: flex;
    	flex-wrap: wrap;
	}
	&__btn {
		display: inline-block;
	  	width: 24.69%;
	  	padding-top: 60px;
	  	text-align: center;
	  	background-image: url('/i/about-white.png');
	  	background-repeat: no-repeat;
	  	background-position: top 10px center;
	  	color: @color-white;
	  	font-family: @FiraSans;
	  	font-weight: 600;
	  	font-style: italic;
		&:hover {
			color: @color-white;
			text-decoration: underline;
			background-image: url('/i/about-red.png');
		}
	}
	&__title {
		color: @color-white;
		font-family: @Pattaya;
		max-width: 700px;
	}
	&__text {
		color: @color-white;
		max-width: 75%;
	}
}

@media screen and (max-width: 1279px) {

	.about {
		&__btn {
			width: 24.4%;
			font-size: 12px;
		}
		&__title {
			max-width: 500px;
		}
		&__text {
			max-width: 80%;
		}
	}

}

@media screen and (min-width: 1280px) and (max-width: 1599px) {

	.about {
		&__btn {
			width: 24.61%;
		}
	}

}
