// ---- COMMON ---- //

*,
*::before,
*::after {
    box-sizing: border-box;
}

html, 
body { 
  width: 100%; 
  padding: 0; 
  margin: 0; 
}

html{
  height: 100%;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body{
  position: relative;
  height: 100%;
  font: normal normal 17px @TondoCyr, Arial, Tahoma, Verdana, sans-serif;
  background: @color-white;
  color: @color-gray;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}
