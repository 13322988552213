// ---- FOOTER ---- //

.footer {
	margin-left: 16.66%;
	position: relative;
	&__block {
		position: absolute;
		background-color: @color-white;
		top: 0;
		padding-left: 5%;
		padding-bottom: 70px;
		padding-right: 30px;
		width: 400px;
	}
	&__social {
		margin-top: 10px;
		margin-bottom: 50px;
		&-link {
			display: inline-block;
			background-repeat: no-repeat;
			height: 24px;
			cursor: pointer;
			&.m--vk {
				margin-right: 40px;
				width: 40px;
			}
			&.m--youtube {
				width: 58px;
			}
		}
	}
	&__address {
		&-item {
			padding-left: 30px;
			position: relative;
			margin-bottom: 20px;
		}
		&-name {
			display: inline-block;
			font-family: @TondoCyr;
			font-weight: bold;
			color: @color-black;
			cursor: pointer;
			margin-bottom: 5px;
			&:before {
				content: ' ';
				position: absolute;
				top: 3px;
				left: 0;
				width: 14px;
				height: 14px;
				border: 2px solid @color-black;
				border-radius: 1000px;
			}
			&:hover {
				color: @color-orange;
				&:before {
					border-color: @color-orange;
					background-color: @color-orange;
				}
			}
			&.is-active {
				color: @color-orange;
				&:before {
					border-color: @color-orange;
					background-color: @color-orange;
				}
			}
		}
	}
	&__developers {
		position: absolute;
		bottom: 30px;
		right: 30px;
		& span {
			font-size: 14px;
			font-family: @TondoCyr;
			font-weight: normal;
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
			color: gray;
			&:hover {
				color: gray;
			}
		}
		& img {
			display: inline-block;
			vertical-align: middle;
		}
	}
}



@media screen and (max-width: 1279px) {

	.footer {
		margin-left: 25%;
		&__block {
			padding-bottom: 40px;
		}
	}

}


