// ---- REVIEWS ---- //

.reviews {
	padding-top: 50px;
	&__item {
		position: relative;
		display: inline-block;
		width: 48.83%;
		margin-bottom: 45px;
		vertical-align: top;
		&:nth-child(even) {
			padding-left: 30px;
		}
		&.m--last {
			padding-top: 30px;
			&:before {
				content: ' ';
				position: absolute;
				left: 30px;
				top: 0;
				height: 2px;
				width: 25%;
				background-color: @color-black;
			}
		}
		&-text {
			font-family: @TondoCyr;
			font-weight: bold;
			&.m--reviewer {
				font-family: @TondoCyr;
				font-weight: normal;
				color: @color-gray-light;
			}
		}
		&-link {
			margin-right: 50px;
			font-family: @FiraSans;
			font-weight: 600;
			font-style: italic;
			color: @color-orange;
			&:hover {
				color: @color-orange;
				text-decoration: underline;
				& span {
					color: @color-orange;
					text-decoration: underline;
				}
			}
			& span {
				transition: @transition-duration;
				color: @color-orange;
			}
			&:last-child { 
				margin-right: 0;
			}
		}
	}
}

@media screen and (max-width: 1279px) {

	.reviews {
		&__item {
			&:nth-child(even) {
				padding-left: 20px;
			}
			&.m--last {
				padding-top: 15px;
				&:before {
					left: 20px;
				}
			}
			&-link {
				display: inline-block;
			}
		}
	}

}


@media screen and (min-width: 1280px) and (max-width: 1599px) {

	.reviews {
		&__item {
			width: 45%;
			&:nth-child(even) {
				padding-left: 20px;
			}
			&.m--last {
				padding-top: 30px;
				&:before {
					left: 20px;
				}
			}
		}
	}

}