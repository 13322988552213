// ---- VALUE ---- //

.value {
	padding-top: 70px;
	&__slider {
		&-dots {
			& a.active .value__slider-dot {
				color: @color-orange;
				&:before {
					border-color: @color-orange;
					background-color: @color-orange;
				}
			}
		}
		&-dot {
			position: relative;
			float: left;
			width: 25%;
			vertical-align: top;
			padding-left: 30px;
			font-family: @FiraSans;
			font-weight: 600;
			font-style: italic;
			font-size: 17px;
			color: @color-gray-light;
			margin-bottom: 35px;
			padding-right: 15px;
			&:before {
				content: ' ';
				position: absolute;
				top: 4px;
				left: 0;
				width: 14px;
				height: 14px;
				border: 2px solid @color-gray-light;
				border-radius: 7px;
			}
			&:hover {
				color: @color-black;
				cursor: pointer;
			}
			&:hover:before {
				border-color: @color-black;
			}
		}
		&-item {
			&-block {
				padding-right: 80px;
				&-img {
					display: inline-block;
					float: left;
					width: 31.25%;
				}
			}
			&-img {
				width: 100%;
				border-radius: 10px;
			}
			&-text {
				position: relative;
				display: inline-block;
				float: right;
				width: 62.5%;
				padding-top: 35px;
				font-family: @FiraSans;
				font-weight: normal;
				font-style: italic;
				color: @color-black;
				font-size: 25px;
				line-height: 33px;
				&:before {
					content: ' ';
					position: absolute;
					top: 0;
					left: 0;
					width: 80px;
					height: 5px;
					background-color: @color-orange;
				}
			}
		}
	}
}


@media screen and (max-width: 1279px) {

	.value {
		
		&__slider {
			&-dot {
				width: 45%;
				margin-bottom: 20px;
			}
			&-item {
				&-block {
					&-img {
						width: 46.25%;
					}
				}
				&-text {
					width: 47.5%;
					padding-top: 15px;
					font-size: 20px;
					line-height: 24px;
				}
			}
		}
	}

}


@media screen and (min-width: 1280px) and (max-width: 1599px) {

	.value {
		&__slider {
			&-item {
				&-block {
					&-img {
						width: 41.25%;
					}
				}
				&-text {
					width: 52.5%;
					padding-top: 15px;
					font-size: 22px;
					line-height: 30px;
				}
			}
		}
	}

}