// ---- PHOTOS ---- //

.photos {
	padding-top: 50px;
	padding-bottom: 50px;
	&__block {
		position: relative;
	}
	&__slider {
		&-nav {
			position: absolute;
			right: 50%;
			top: -55px;
			&-arrow {
				width: 14px;
				height: 14px;
				cursor: pointer;
				&.m--left {
					position: absolute;
					top: 2px;
					right: -85px;
				}
				&.m--right {
					position: absolute;
					top: 2px;
					left: -85px;
				}
			}
			&-link {
				color: @color-orange;
				font-family: @FiraSans;
				font-weight: 600;
				font-style: italic;
				&:hover {
					color: @color-orange;
					text-decoration: underline;
				}
				&:before {
					content: ' ';
					width: 40px;
					height: 2px;
					background-color: @color-orange;
					position: absolute;
					top: 50%;
					left: -60px;
				}
				&:after {
					content: ' ';
					width: 40px;
					height: 2px;
					background-color: @color-orange;
					position: absolute;
					top: 50%;
					right: -60px;
				}
			}
		}
		&-block {
			overflow: hidden; 
		}
		&-item {
			position: relative;
			margin-right: 80px;
			max-width: 480px;
			&-img {
				width: 100%;
			}
			&-text {
				font-family: @TondoCyr;
				font-weight: bold;
				padding-top: 35px;
				padding-left: 75px;
				padding-bottom: 5px;
				max-width: 90%;
			}
			&-link {
				font-family: @FiraSans;
				font-weight: 600;
				font-style: italic;
				color: @color-orange;
				margin-left: 75px;
				&:hover {
					color: @color-orange;
					text-decoration: underline;
				}
				&.m--full {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 1279px) {

	.photos {
		&__slider {
			&-nav {
				right: 22%;
			}
			&-item {
				max-width: 340px;
			}
		}
	}

}


@media screen and (min-width: 1280px) and (max-width: 1599px) {

	.photos {
		&__slider {
			&-nav {
				right: 45%;
			}
			&-item {
				max-width: 340px;
			}
		}
	}

}