// ---- DANCE ---- //

.dance {
	padding-top: 50px;
	&__item {
		position: relative;
		width: 24%;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 40px;
		&:hover .dance__item-bgd {
			background-color: @color-orange;
	  		background-blend-mode: screen;
	  		background-repeat: no-repeat;
	  		transition: @transition-duration;
		}
		&-bgd {
			width: 120px;
			height: 120px;
			border-radius: 60px;
		}
		&-block {
			padding-left: 60px;
			padding-top: 4px;
			max-width: 80%;
			&:hover .dance__item-block-link {
				text-decoration: underline;
			}
			&-link {
				font-family: @FiraSans;
				font-weight: 600;
				font-style: italic;
				color: @color-orange;
				transition: @transition-duration;
			}
		}
		&-text {
			padding-bottom: 5px;
		}

		&-link {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			&:hover ~ .dance__item-block .dance__item-block-link {
				text-decoration: underline;
			}
			&.m--no-hover-border {
				&:hover {
					border: 0 !important;
				}
			}
		}
	}
}

@media screen and (max-width: 1279px) {

	.dance {
		&__item {
			width: 49.6%;
		}
	}

}


@media screen and (min-width: 1280px) and (max-width: 1599px) {

	.dance {
		&__item {
			width: 33%;
		}
	}

}