// ---- ASIDE ---- //

.aside {
	position: fixed;
	left: 0;
	top: 0;
	display: block;
	
/*	min-height: 100%;
	width: 16.66%;*/
	height: 100%; /*new*/
	z-index: 200;
	background-color: rgba(42, 53, 127, 0.8); 


	&__block {
		padding-top: 18px;
		background-image: url('/i/bg/gradient.png');
		background-repeat: no-repeat;
		background-position: 50% 0;
		height: 100%;

		/*new*/
		overflow-y: auto;

	    -ms-overflow-style: none; 
	    scrollbar-width: none; 

		&::-webkit-scrollbar { 
	        display: none;
	        width: 0;
	    	height: 0;
	    }
	    /*new*/
	}
	&__logo {
		text-align: center;
		padding-top: 18px;
		padding-bottom: 20px;
		margin-top: 56px; /*new*/
	}
	&__callback {
		background-image: url('/i/ribbon.png');
		background-repeat: no-repeat;
		background-position: top right;
		/*margin-right: -70px;*/
		padding-bottom: 20px;
		padding-left: 50px;
		padding-top: 8px;

		/*new*/
		position: absolute;
    	width: ~"calc(100% + 70px)";

    	/*new*/

		&-btn {
			font-family: @FiraSans;
			font-weight: normal;
			font-style: italic;
			font-size: 17px;
			color: @color-white;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
				color: @color-white;
			}
		}
		&-phone {
			margin-left: 15px;
			font-family: @FiraSans;
			font-weight: normal;
			font-style: italic;
			font-size: 17px;
			color: @color-white;
			&:hover {
				color: @color-white;
			}
		}
	}
	&__nav {
		padding-left: 50px;
		padding-right: 20px;
		&-menu {
			&.m--top {
				font-family: @TondoCyr;
				font-weight: bold;
				font-style: normal;
			}
			&.m--bottom {
				font-family: @TondoCyr;
				font-weight: normal;
				font-style: normal;
				padding-top: 25px;
			}
			&-item {
				margin-bottom: 20px;
			}
			&-link {
				font-size: 15px;
				color: @color-white;
				&:hover {
					text-decoration: underline;
					color: @color-white;
				}
				&.is-active {
					text-decoration: underline;
				}
			}
		}
	}
}

@media screen and (max-width: 1279px) {

	.aside {
		width: 25%;
		&__block {

		}
		&__callback {
			padding-left: 20px;
			&-phone {
				position: absolute;
				top: 44px; /*new*/
				left: 0;
			}
		}
		&__nav {
			padding-left: 20px;
			&-menu {
				&-item {
					margin-bottom: 13px;
				}
			}
		}

	}
	
}

@media screen and (min-width: 1280px) and (max-width: 1599px) {

	.aside {
		&__block {
			&.m--bottom {
				padding-left: 20px;
			}
		}
		&__callback {
			
		}
		&__nav {
			padding-left: 20px;
		}
	}
	
}

@media screen and (min-width: 1280px) and (max-width: 1799px) {
	.aside {
		&__callback {
			&-phone {
				position: absolute;
				top: 44px; /*new*/
				left: 35px;
			}
		}
	}
}

@media screen and (min-width: 1920px) {

	.aside {
		width: 320px;
	}

}