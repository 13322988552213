// ---- BTN ---- //

.btn {
	display: inline-block;
	text-align: center;
	cursor: pointer;
	outline: none;
	font-family: @TondoCyr;
	font-weight: bold;
	transition: @transition-duration;
	font-size: 17px;

	&.m--about-offer {
		width: 240px;
		border-radius: 4px;
		padding-top: 20px;
		padding-bottom: 20px;
		font-size: 17px;
		text-decoration: none;
	}

	&.m--offices {
		width: 22%;
		border-radius: 8px;
		font-size: 15px;
		padding-top: 35px;
		padding-bottom: 35px;
		text-decoration: none;
	}

	&.m--submit {
		width: 320px;
		padding-top: 22px;
		padding-bottom: 22px;
		border-radius: 4px;
		font-size: 17px;
	}	

	&.m--transparent {
		background-color: transparent;
		border: 2px solid @color-white;
		color: @color-white;
		&:hover {
			background-color: @color-white;
			color: @color-blue;
		}
	}

	&.m--aqua {
		background-color: @color-orange;
		border: 2px solid @color-orange;
		color: @color-white;
		&:hover {
			background-color: @color-white;
			color: @color-orange;
		}
	}

	&.m--white {
		background-color: @color-white;
		border: 2px solid @color-orange;
		color: @color-orange;
		&:hover {
			background-color: @color-orange;
			color: @color-white;
		}
	}
}

@media screen and (max-width: 1280px) {

	.btn {
		display: inline-block;
		text-align: center;
		cursor: pointer;
		font-family: @TondoCyr;
		font-weight: bold;
		transition: @transition-duration;

		&.m--about-offer {
			width: 240px;
			border-radius: 4px;
			padding-top: 20px;
			padding-bottom: 20px;
			font-size: 17px;
			text-decoration: none;
		}

		&.m--offices {
			width: 40%;
			margin-bottom: 20px;
			border-radius: 8px;
			font-size: 15px;
			padding-top: 35px;
			padding-bottom: 35px;
			text-decoration: none;
		}

		.m--submit {
			width: 320px;
			padding-top: 24px;
			padding-bottom: 24px;
			border-radius: 4px;
			font-size: 17px;
		}	

		&.m--transparent {
			background-color: transparent;
			border: 2px solid @color-white;
			color: @color-white;
			&:hover {
				background-color: @color-white;
				color: @color-blue;
			}
		}

		&.m--aqua {
			background-color: @color-orange;
			border: 2px solid @color-orange;
			color: @color-white;
			&:hover {
				background-color: @color-white;
				color: @color-orange;
			}
		}

		&.m--white {
			background-color: @color-white;
			border: 2px solid @color-orange;
			color: @color-orange;
			&:hover {
				background-color: @color-orange;
				color: @color-white;
			}
		}
	}

}

@media screen and (min-width: 1281px) and (max-width: 1600px) {

	.btn {

		&.m--offices {
			font-size: 13px;
		}

	}

}