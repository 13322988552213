// ---- LAYOUT ---- //

.wrapper {
	position: relative;
	min-width: 1000px;
	max-width: 1920px;
	margin: 0 auto;
}

.wrapper-content {
	padding-left: 16.66%;
}

.page {
	padding-top: 15px;
}

.content {
	padding-bottom: 50px;
	&__block {
		font-size: 0;
		&-img {
			width: 30%;
			float: left;
		}
	}
	&__item {
		position: relative;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 12px;
		&.m--gallery-cat {
			margin-bottom: 50px;
			width: 31.9%;
			margin-right: 6px;
			margin-left: 6px;
		}
		&.m--gallery {
			height: 250px;
			width: 31.9%;	
			margin-right: 6px;
			margin-left: 6px;
		}
		&.m--news {
			width: 31%;
			margin-right: 6px;
			margin-left: 6px;
		}
		&.m--news-cat {
			width: 50%;
			margin-bottom: 30px;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	&__img {
		width: 100%;
		vertical-align: top;
		&.m--news-cat {
			width: 100%;
			border-radius: 10px;
		}
		&.m--news {
			width: 300px;
			float: left;
			border-radius: 10px;
			margin-right: 20px;
			margin-bottom: 15px;
		}
		&.m--gallery {
			height: 100%;
			object-fit: cover;
		}
	}
	&__link {
		font-size: 17px;
		color: @color-gray;
		&.m--gallery-cat {
			display: block;
			margin-top: 15px;
			&:hover {
				border: 0;
				color: @color-orange;
			}
		}
		&.m--news-cat {
			margin-left: 30%;
		}
		&.m--full {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			&:hover {
				border: 0;
			}
			&:hover ~ .content__link.m--gallery-cat {
				border: 0;
				color: @color-orange;
			}
		}
	}
	&__text {
		font-size: 17px;
		&.m--reviews {
			margin-bottom: 40px;
		}
		&.m--news-cat {
			margin-left: 35%;
		}
	}
	&__date {
		font-size: 17px;
		margin-left: 35%;
		color: @color-gray-light;
		margin-bottom: 15px;
	}
	&__title {
		&.m--news-cat {
			margin-left: 35%;
			font-size: 21px;
			line-height: 25px;
			margin-bottom: 5px;
		}
	}
}

.content ul {
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: 22px;
	list-style-position: outside;
	margin-left: 0;
	li {
		font-size: 17px;
		font-family: @TondoCyr;
		font-weight: normal;
		color: @color-gray;
		span {
			color: @color-gray;
		}
		&:before {
			content: '— ';
			color: @color-orange;
		}
	}
}

.content ol {
	margin-top: 20px;
	margin-bottom: 20px;
	counter-reset: numeric;
	li {
		color: @color-gray;
		font-size: 17px;
		font-family: @TondoCyr;
		font-weight: normal;
		&:before {
			counter-increment: numeric;
			content: counter(numeric) '.';
			color: @color-orange;
			padding-right: 7px;
		}
	}
}

.breadcrumbs {
	margin-top: 20px;
	margin-bottom: 20px;
	&__item {
		display: inline-block;
		font-size: 17px;
		font-family: @TondoCyr;
		font-weight: normal;
		color: @color-gray-light;
		& + .breadcrumbs__item:before {
			content: "/\00a0";
		}
	}
	&__link {
		color: @color-gray-light;
		&:hover {
			border-bottom: 1px solid @color-orange;
		}
	}
}

p {
	margin-bottom: 15px;
}

.table,
.content table,
table {
	&__row,
	tr {
		border-bottom: 1px solid rgba(229, 229, 229, 1);
	}
	&__coloumn,
	td {
		padding-top: 14px;
		padding-bottom: 11px;
		padding-right: 60px;
		vertical-align: top;
		color: @color-gray;
	}
	thead {
		tr {
			&:first-child {
				border-bottom: 2px solid @color-orange;

				& td {
					font-family: @TondoCyr;
					font-weight: bold;
				}
			}
		}
	}
}

.container {

	&.m--left {
		margin-left: 10%;
	}

	&.m--banner {
		margin-right: 16.66%;
	}

	&.m--right {
		margin-right: 10%;
	}

	&.m--center {
		margin-left: 10%;
		margin-right: 10%;
	}

	&.m--footer {
		margin-left: 5%;
	}

}

.media-left {
	float: left;
	border-radius: 10px;
	margin-right: 20px;
	margin-bottom: 15px;
	iframe,
	img {
		border-radius: 10px;
	}
}

.media-right {
	float: right;	
	border-radius: 10px;
	margin-left: 20px;
	margin-bottom: 15px;
	iframe,
	img {
		border-radius: 10px;
	}
}

.navbar {
	padding-top: 50px;
	&__item {
		margin-right: 10px;
		&.m--current {
			font-size: 20px;
			font-family: @TondoCyr;
			font-weight: bold;
		}
		&:hover {
			border: 0 !important;
		}
	}
}

@media screen and (max-width: 1279px) {

	.wrapper-content {
		padding-left: 25%;
	}

	.content {
		&__item {
			&.m--gallery-cat {
				width: 48%;
			}
			&.m--gallery {
				width: 48%;
			}
		}
	}

}