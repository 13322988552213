// ---- FORM ---- //
.page {
    .form {
        background-color: transparent;
        padding-bottom: 40px;
    }
}
.form {
    background-color: @color-white;
    padding-bottom: 10px;
    &__input {
        width: 320px;
        padding-top: 35px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
        margin-right: 20px;
        outline: none;
        font-family: @TondoCyr;
        font-weight: normal;
        color: @color-gray;
        font-size: 17px;
        &.m--white {
            border: 1px solid @color-orange;
            border-radius: 4px;
            background-color: @color-white;
        }
    }
    &__item {
        width: 48%;
        position: relative;
        display: inline-block;
        &.m--remark {
            width: 100%;
        }
        &.m--error {
            .form__input {
                background-color: rgba(241, 145, 70, 1);
            }
            .form__label:after {
                content: '(ошибка)';
                margin-left: 5px;
                color: @color-orange;
            }
        }
    }
    &__label {
        position: absolute;
        top: 11px;
        left: 20px;
        font-family: @TondoCyr;
        font-weight: bold;
        color: @color-gray;
    }
    &__btn {
        margin-top: 18px;
        margin-bottom: 18px;
    }
}

.form-item__error {
    & .form__input {
        background-color: rgba(241, 145, 70, 1);
    }
    & .form__label:after {
        content: '(ошибка)';
        margin-left: 5px;
        color: @color-orange;
    }
}

.captcha {
    margin-top: 10px;
    &.m--error {
        & .captcha__input {
            background-color: rgba(241, 145, 70, 1);
        }
    }
    &__input {
        outline: none;
        border: 0;
        border-radius: 4px;
        border: 1px solid @color-orange;
        font-family: @TondoCyr;
        font-weight: normal;
        color: @color-gray;
        font-size: 17px;
    }
}


