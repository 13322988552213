// ---- BANNER ---- //

.banner {
	position: relative;
	height: 800px;
	background-repeat: no-repeat;
	&__bgd {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		top: 0;
		background: url('/i/bg/shadow.png') bottom no-repeat rgba(42, 53, 127, .68) !important;
		z-index: 100;
		padding-left: 16.66%;
	}
	&__title {
		padding-bottom: 20px;
		font-family: @FiraSans;
		font-weight: bold;
		font-style: italic;
		font-size: 36px;
		color: @color-white;
	}
	&__text {
		color: @color-white;
		max-width: 55%;
	}
	&__btn {
		margin-top: 35px;
		margin-right: 30px; 
 		color: @color-white;
 		&:hover {
 			color: @color-white;
 		}
	}
	&__slider {

		&-dots {
			margin-left: 4px;
			margin-bottom: 15px;
		}
		&-dot {
			display: inline-block;
			margin-right: 24px;
			width: 12px;
			height: 12px;
			border-radius: 6px;
			background-color: @color-white;
			&:last-child {
				margin-right: 0;
			}
			&.active {
				background-color: rgba(241, 122, 28, 1);
			}
		}
	}
}

@media screen and (max-width: 1279px) {

	.banner {
		padding-top: 40px;
		padding-bottom: 30px;
		&__bgd {
			padding-left: 25%;
		}
		&__text {
			color: @color-white;
			max-width: 80%;
		}
		&__btn {
			margin-top: 0;
		}
		&__back {
			background: none !important;
		}
	}

	.about {
		&__btn {
			width: 24.44%;
			font-size: 12px;
		}
		&__title {
			max-width: 500px;
		}
		&__text {
			max-width: 80%;
		}
	}

}

@media screen and (min-width: 1280px) and (max-width: 1599px) {

	.banner {
		&__text {
			color: @color-white;
			max-width: 60%;
		}
		&__back {
			background: none !important;
		}
	}
	.about {
		&__btn {
			width: 24.61%;
		}
	}

}
