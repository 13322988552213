// ---- MAIN ---- //

.main {
	background-image: url('/i/bg/main-left.png');
	background-repeat: no-repeat;
	background-position: top 250px left;
	&__background {
		background-image: url('/i/bg/main-right.png');
		background-repeat: no-repeat;
		background-position: top right;
	}
}
